import { gql, useMutation } from '@apollo/client';
import { DematAccountType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanel, FormPanelWithReadMode } from 'components/FormPanel';

const ADD_DEMAT_ACCOUNT_MUTATION = gql(`
  mutation AddDematAccount(
    $investmentEntityId: ID!
    $depositoryType: DematDepositoryTypeEnumType!
    $dpId: String!
    $clientId: String!
    $dematAccountNumber: String!
  ) {
    addDematAccount(
      investmentEntityId: $investmentEntityId
      depositoryType: $depositoryType
      dpId: $dpId
      clientId: $clientId
      dematAccountNumber: $dematAccountNumber
    ) {
      id
      depositoryType
      dpId
      clientId
      dematAccountNumber
    }
  }
`);

const AddDematAccountDetails: FC<{
  investmentEntityId: string;
  dematAccountData: DematAccountType;
  investmentEntityName: string;
  pan: string;
}> = ({ investmentEntityId, dematAccountData, investmentEntityName, pan }) => {
  const [addDematAccount, { loading, error }] = useMutation(ADD_DEMAT_ACCOUNT_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: `InvestmentEntityType:${investmentEntityId}`,
        fields: {
          dematAccount() {
            const newDematAccountRef = cache.writeFragment({
              data: data.addDematAccount,
              fragment: gql(`
                fragment NewDematAccount on DematAccountType {
                  id
                  depositoryType
                  dpId
                  clientId
                  dematAccountNumber
                }
              `),
            });
            return newDematAccountRef;
          },
        },
      });
    },
  });

  const getDematFormFields = (dematAccount: DematAccountType) => {
    return (
      <>
        <FormInput
          type="text"
          fieldName="nameOfAccountHolder"
          label="Name of Account Holder"
          defaultValue={investmentEntityName}
          validators={{ required: true }}
          editable={false}
        />
        <FormInput
          type="text"
          fieldName="pan"
          label="PAN"
          defaultValue={pan}
          validators={{ required: true }}
          editable={false}
        />
        <FormInput
          type="radio-group"
          fieldName="depositoryType"
          label="Depository Type"
          defaultValue={dematAccount?.depositoryType}
          options={[
            { label: 'NSDL', value: 'NSDL' },
            { label: 'CDSL', value: 'CDSL' },
          ]}
          validators={{ required: true }}
        />
        <FormInput
          type="text"
          fieldName="dpId"
          label="DP ID"
          defaultValue={dematAccount?.dpId}
          validators={{ required: true }}
        />
        <FormInput
          type="text"
          fieldName="clientId"
          label="Client ID"
          defaultValue={dematAccount?.clientId}
          validators={{ required: true }}
        />
        <FormInput
          type="text"
          fieldName="dematAccountNumber"
          label="Demat Account Number"
          defaultValue={dematAccount?.dematAccountNumber}
          validators={{ required: true }}
        />
      </>
    );
  };

  return (
    <>
      {dematAccountData ? (
        <FormPanelWithReadMode
          loading={loading}
          error={error}
          title="Demat Account Details"
          onSubmit={data => {
            return addDematAccount({
              variables: {
                investmentEntityId: investmentEntityId,
                nameOfAccountHolder: data.nameOfAccountHolder,
                depositoryType: data.depositoryType,
                dpId: data.dpId,
                clientId: data.clientId,
                dematAccountNumber: data.dematAccountNumber,
              },
            });
          }}
        >
          {getDematFormFields(dematAccountData)}
        </FormPanelWithReadMode>
      ) : (
        <FormPanel
          loading={loading}
          error={error}
          onSubmit={data => {
            return addDematAccount({
              variables: {
                investmentEntityId: investmentEntityId,
                nameOfAccountHolder: data.nameOfAccountHolder,
                depositoryType: data.depositoryType,
                dpId: data.dpId,
                clientId: data.clientId,
                dematAccountNumber: data.dematAccountNumber,
              },
            });
          }}
        >
          {getDematFormFields(dematAccountData)}
        </FormPanel>
      )}
    </>
  );
};

export default AddDematAccountDetails;
