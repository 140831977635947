import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';

import Approvals from './Approvals';
import ApproveDealsPage from './Approvals/ApproveDeals';
import ApproveFundsPage from './Approvals/ApproveFunds';
import ApproveInvestmentEntities from './Approvals/ApproveInvestmentEntities';
import ApprovePostsPage from './Approvals/ApprovePosts';
import ApproveSyndicates from './Approvals/ApproveSyndicates';
import ApproveUsersPage from './Approvals/ApproveUsers';
import Assets from './Assets';
import AssetsCompaniesPage from './Assets/Companies';
import ConvertibleAssetsPage from './Assets/ConvertibleAssets';
import ShareAssetsPage from './Assets/ShareAssets';
import AuthenticateOTP from './AuthenticateOTP';
import Companies from './Companies';
import Company from './Company';
import CompanyAssets from './Company/Assets';
import CompanyDeals from './Company/Deals';
import CompanyEvents from './Company/Events';
import CompanyExits from './Company/Exits';
import CompanyInformation from './Company/Information';
import ControlPanel from './ControlPanel';
import Dashboard from './Dashboard';
import Deal from './Deal';
import DealAdminPage from './Deal/Admin';
import DealAssets from './Deal/Assets';
import DealClosings from './Deal/Closings';
import DealInvites from './Deal/DealInvites';
import DealDocuments from './Deal/Documents';
import DealInformation from './Deal/Information';
import BasicInformation from './Deal/Information/BasicInformation';
import CarryGPCommitment from './Deal/Information/CarryGPCommitment';
import DealCompanyInformation from './Deal/Information/CompanyInformation';
import DealMemo from './Deal/Information/Memo/Memo';
import DealOtherInvestors from './Deal/Information/OtherInvestors';
import DealRisksDisclosures from './Deal/Information/RiskDisclosures';
import DealOverview from './Deal/Overview';
import DealDigest from './DealDigest';
import DealExitPage from './DealExit';
import DealExitsPage from './DealExits';
import Deals from './Deals';
import FundPage from './Fund';
import FundClosings from './Fund/FundClosings';
import FundInformation from './Fund/Information';
import FundInvestments from './Fund/Investments';
import FundsPage from './Funds';
import InvestmentEntities from './InvestmentEntities';
import InvestmentEntity from './InvestmentEntity';
import InvestmentEntityBankAccounts from './InvestmentEntity/BankAccounts';
import InvestmentEntityDematAccount from './InvestmentEntity/DematAccount';
import InvestmentEntityDocuments from './InvestmentEntity/Documents';
import InvestmentEntityInformation from './InvestmentEntity/Information';
import InvestmentEntityInvestments from './InvestmentEntity/Investments';
import InvestmentEntityInvestorApplication from './InvestmentEntity/InvestorApplication';
import InvestmentEntityKyc from './InvestmentEntity/Kyc';
import Login from './Login';
import Post from './Post';
import Posts from './Posts';
import RouteNotFound from './RouteNotFound';
import Settings from './Settings';
import Syndicate from './Syndicate';
import SyndicateDeals from './Syndicate/Deals';
import SyndicateInformation from './Syndicate/Information';
import SyndicateLeads from './Syndicate/Leads';
import SyndicateUsers from './Syndicate/Users';
import Syndicates from './Syndicates';
import Unauthorized from './Unauthorized';
import User from './User';
import UserInformation from './User/Information';
import UserInvestmentEntities from './User/InvestmentEntities';
import UserNotificationPreferences from './User/NotificationPreferences';
import Users from './Users';

const router = createBrowserRouter([
  {
    path: '*',
    element: <RouteNotFound />,
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/authenticate-otp',
    element: <AuthenticateOTP />,
  },
  // Users
  {
    path: '/users',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        ),
      },
      {
        path: ':userId',
        element: (
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <UserInformation />
              </ProtectedRoute>
            ),
          },
          {
            path: 'notification-preferences',
            element: (
              <ProtectedRoute>
                <UserNotificationPreferences />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-entities',
            element: (
              <ProtectedRoute>
                <UserInvestmentEntities />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },

  // Investment Entities
  {
    path: '/investment-entities',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <InvestmentEntities />
          </ProtectedRoute>
        ),
      },
      {
        path: ':investmentEntityId',
        element: (
          <ProtectedRoute>
            <InvestmentEntity />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <InvestmentEntityInformation />
              </ProtectedRoute>
            ),
          },
          {
            path: 'kyc',
            element: (
              <ProtectedRoute>
                <InvestmentEntityKyc />
              </ProtectedRoute>
            ),
          },
          {
            path: 'demat',
            element: (
              <ProtectedRoute>
                <InvestmentEntityDematAccount />
              </ProtectedRoute>
            ),
          },
          {
            path: 'bank-accounts',
            element: (
              <ProtectedRoute>
                <InvestmentEntityBankAccounts />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investor-application',
            element: (
              <ProtectedRoute>
                <InvestmentEntityInvestorApplication />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investments',
            element: (
              <ProtectedRoute>
                <InvestmentEntityInvestments />
              </ProtectedRoute>
            ),
          },
          {
            path: 'documents',
            element: (
              <ProtectedRoute>
                <InvestmentEntityDocuments />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  // Companies
  {
    path: '/companies',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Companies />
          </ProtectedRoute>
        ),
      },
      {
        path: ':companyId',
        element: (
          <ProtectedRoute>
            <Company />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <CompanyInformation />
              </ProtectedRoute>
            ),
          },
          {
            path: 'assets',
            element: (
              <ProtectedRoute>
                <CompanyAssets />
              </ProtectedRoute>
            ),
          },
          {
            path: 'deals',
            element: (
              <ProtectedRoute>
                <CompanyDeals />
              </ProtectedRoute>
            ),
          },
          {
            path: 'events',
            element: (
              <ProtectedRoute>
                <CompanyEvents />
              </ProtectedRoute>
            ),
          },
          {
            path: 'exits',
            element: (
              <ProtectedRoute>
                <CompanyExits />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },

  // Deals
  {
    path: '/deals',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Deals />
          </ProtectedRoute>
        ),
      },
      {
        path: ':dealId',
        element: (
          <ProtectedRoute>
            <Deal />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <DealOverview />
              </ProtectedRoute>
            ),
          },
          {
            path: 'information',
            element: (
              <ProtectedRoute>
                <DealInformation />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <BasicInformation />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'carry-gp-commitment',
                element: (
                  <ProtectedRoute>
                    <CarryGPCommitment />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'other-investors',
                element: (
                  <ProtectedRoute>
                    <DealOtherInvestors />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'company-information',
                element: (
                  <ProtectedRoute>
                    <DealCompanyInformation />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'memo',
                element: (
                  <ProtectedRoute>
                    <DealMemo />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'risks-and-disclosures',
                element: (
                  <ProtectedRoute>
                    <DealRisksDisclosures />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'assets',
            element: (
              <ProtectedRoute>
                <DealAssets />
              </ProtectedRoute>
            ),
          },
          {
            path: 'closings',
            element: (
              <ProtectedRoute>
                <DealClosings />
              </ProtectedRoute>
            ),
          },
          {
            path: 'invites',
            element: (
              <ProtectedRoute>
                <DealInvites />
              </ProtectedRoute>
            ),
          },
          {
            path: 'documents',
            element: (
              <ProtectedRoute>
                <DealDocuments />
              </ProtectedRoute>
            ),
          },
          {
            path: 'admin',
            element: (
              <ProtectedRoute>
                <DealAdminPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },

  // Syndicates
  {
    path: '/syndicates',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Syndicates />
          </ProtectedRoute>
        ),
      },
      {
        path: ':syndicateId',
        element: (
          <ProtectedRoute>
            <Syndicate />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <SyndicateInformation />
              </ProtectedRoute>
            ),
          },
          {
            path: 'deals',
            element: (
              <ProtectedRoute>
                <SyndicateDeals />
              </ProtectedRoute>
            ),
          },
          {
            path: 'leads',
            element: (
              <ProtectedRoute>
                <SyndicateLeads />
              </ProtectedRoute>
            ),
          },
          {
            path: 'users',
            element: (
              <ProtectedRoute>
                <SyndicateUsers />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  // Funds
  {
    path: '/funds',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <FundsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: ':fundId',
        element: (
          <ProtectedRoute>
            <FundPage />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <FundInformation />
              </ProtectedRoute>
            ),
          },
          {
            path: 'commitments',
            element: (
              <ProtectedRoute>
                <FundClosings />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investments',
            element: (
              <ProtectedRoute>
                <FundInvestments />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/approvals',
    element: (
      <ProtectedRoute>
        <Approvals />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <ApproveInvestmentEntities />
          </ProtectedRoute>
        ),
      },
      {
        path: 'users',
        element: (
          <ProtectedRoute>
            <ApproveUsersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'deals',
        element: (
          <ProtectedRoute>
            <ApproveDealsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'syndicates',
        element: (
          <ProtectedRoute>
            <ApproveSyndicates />
          </ProtectedRoute>
        ),
      },
      {
        path: 'funds',
        element: (
          <ProtectedRoute>
            <ApproveFundsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'posts',
        element: (
          <ProtectedRoute>
            <ApprovePostsPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/deal-exits',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <DealExitsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: ':dealExitId',
        element: (
          <ProtectedRoute>
            <DealExitPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/settings',
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: '/posts',
    element: (
      <ProtectedRoute>
        <Posts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/posts/:postId',
    element: (
      <ProtectedRoute>
        <Post />
      </ProtectedRoute>
    ),
  },
  {
    path: '/deal-digest',
    element: (
      <ProtectedRoute>
        <DealDigest />
      </ProtectedRoute>
    ),
  },
  {
    path: '/control-panel',
    element: (
      <ProtectedRoute>
        <ControlPanel />
      </ProtectedRoute>
    ),
  },
  {
    path: '/assets',
    element: (
      <ProtectedRoute>
        <Assets />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <ShareAssetsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'convertible-assets',
        element: (
          <ProtectedRoute>
            <ConvertibleAssetsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'companies',
        element: (
          <ProtectedRoute>
            <AssetsCompaniesPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
