import { DealExitTypeEnumType } from '__generated__/graphql';

export default function exitTypeToReadable(type: DealExitTypeEnumType): {
  label: string;
  color: string;
} {
  switch (type) {
    case DealExitTypeEnumType.Acquisition:
      return {
        label: 'Acquisition',
        color: 'gray',
      };
    case DealExitTypeEnumType.Ipo:
      return {
        label: 'IPO',
        color: 'gray',
      };
    case DealExitTypeEnumType.Fundraise:
      return {
        label: 'Fundraise',
        color: 'gray',
      };
    case DealExitTypeEnumType.Other:
      return {
        label: 'Other',
        color: 'gray',
      };
    case DealExitTypeEnumType.SharesBuyback:
      return {
        label: 'Shares Buyback',
        color: 'gray',
      };
    case DealExitTypeEnumType.ShutDown:
      return {
        label: 'Shut down',
        color: 'gray',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
