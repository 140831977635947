import { gql, useQuery } from '@apollo/client';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import AddDematAccountDetails from './AddDematAccountDetails';

const INVESTMENT_ENTITY_DEMAT_QUERY = gql(`
  query InvestmentEntityDemat($id: ID!) {
    investmentEntity(id: $id) {
      id
      name
      kyc {
        id
        pan
      }
      dematAccount {
        id
        depositoryType
        dpId
        clientId
        dematAccountNumber
        status
      }
    }
  }
`);

const InvestmentEntityDematAccount: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };
  const { loading, data, error, refetch } = useQuery(INVESTMENT_ENTITY_DEMAT_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });
  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  return (
    <>
      <AddDematAccountDetails
        investmentEntityId={investmentEntityId}
        dematAccountData={data.investmentEntity.dematAccount}
        investmentEntityName={data.investmentEntity.name}
        pan={data.investmentEntity.kyc?.pan}
      />
    </>
  );
};

export default InvestmentEntityDematAccount;
