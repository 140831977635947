import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import Button from 'primitives/Button';
import ConfirmButton from 'primitives/ConfirmButton';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

const DEAL_EXIT_QUERY = gql(`
  query getDealExit($id: ID!) {
    dealExit(id: $id) {
      id
      type
      percentageOfShares
      pricePerShare
      status
      deal {
        id
        schemeName
        companyInformation {
          id
          company {
            id
            name
          }
        }
      }
      exitedAt
    }
  }
`);

const GENERATE_DISTRIBUTIONS_MUTATION = gql(`
  mutation generateDistributions($dealExitId: ID!) {
    generateDistributions(dealExitId: $dealExitId) {
      nodes {
        id
        investmentEntity {
          id
          name
        }
        isNri
        investedAmount
        returnOnInvestedAmount
        distributedAmount
        carryEarned
        carryPaid
        taxDeduction
      }
    }
  }
`);

const REMOVE_DEAL_EXIT_MUTATION = gql(`
  mutation removeDealExit($id: ID!) {
    removeDealExit(id: $id) {
      id
    }
  }
`);

const DealExitPage: FC = () => {
  const navigate = useNavigate();
  const { dealExitId } = useParams<{ dealExitId: string }>() as { dealExitId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_EXIT_QUERY, {
    variables: {
      id: dealExitId,
    },
  });

  const [
    generateDistributions,
    {
      data: generateDistributionsData,
      loading: generateDistributionsLoading,
      error: generateDistributionsError,
    },
  ] = useMutation(GENERATE_DISTRIBUTIONS_MUTATION, {
    variables: {
      dealExitId,
    },
  });

  const [removeDealExit, { loading: removeDealExitLoading, error: removeDealExitError }] =
    useMutation(REMOVE_DEAL_EXIT_MUTATION, {
      variables: {
        id: dealExitId,
      },
      refetchQueries: ['fetchExitsByDeal', 'fetchDealExits', 'fetchDealExitsByCompany'],
      onCompleted() {
        navigate('/deal-exits');
      },
    });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const dealExit = data.dealExit;

    return (
      <>
        <SidebarLayoutNavigation
          title={dealExit.deal.schemeName || dealExit.deal.companyInformation.company.name}
          subTitle="Deal Exit"
          onBackButtonClick={() => navigate(-1)}
        />
        <SidebarLayoutContent>
          <DetailsPanel
            data={[
              {
                label: 'Deal',
                value: dealExit.deal.schemeName,
                type: 'LINK',
                navigateTo: `/deals/${dealExit.deal.id}`,
              },
              {
                label: 'Company',
                value: dealExit.deal.companyInformation.company.name,
              },
              {
                label: 'Type',
                value: dealExit.type,
              },
              {
                label: 'Percentage of Shares',
                value: dealExit.percentageOfShares,
                type: 'PERCENTAGE',
              },
              {
                label: 'Price Per Share',
                value: dealExit.pricePerShare,
                type: 'CURRENCY',
              },
              {
                label: 'Status',
                value: dealExit.status,
                type: 'STATUS',
              },
            ]}
          />
          <div>
            {generateDistributionsError && <ErrorMessage error={generateDistributionsError} />}
            <Button onClick={generateDistributions} loading={generateDistributionsLoading}>
              Generate Distributions
            </Button>
          </div>
          <div>
            {removeDealExitError && <ErrorMessage error={removeDealExitError} />}
            <ConfirmButton
              onConfirm={removeDealExit}
              loading={removeDealExitLoading}
              title="Are you sure you want to revert this deal exit?"
              description="This action will remove this exit and associated data. It was also revert the status of the deal back to FINALISED and the associated share assets back to ACTIVE."
            >
              Remove Deal Exit
            </ConfirmButton>
          </div>
          <DataTable
            data={generateDistributionsData?.generateDistributions.nodes || []}
            columns={[
              {
                label: 'Investment Entity',
                fieldName: 'investmentEntity.name',
              },
              {
                label: 'Is NRI',
                fieldName: 'isNri',
                type: 'BOOLEAN',
              },
              {
                label: 'Invested Amount',
                fieldName: 'investedAmount',
                type: 'CURRENCY',
              },
              {
                label: 'Return on Invested Amount',
                fieldName: 'returnOnInvestedAmount',
                type: 'CURRENCY',
              },
              {
                label: 'Carry Earned',
                fieldName: 'carryEarned',
                type: 'CURRENCY',
              },
              {
                label: 'Carry Paid',
                fieldName: 'carryPaid',
                type: 'CURRENCY',
              },
              {
                label: 'Tax Deduction',
                fieldName: 'taxDeduction',
                type: 'CURRENCY',
              },
              {
                label: 'Distributed Amount',
                fieldName: 'distributedAmount',
                type: 'CURRENCY',
              },
            ]}
          />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default DealExitPage;
