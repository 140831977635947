import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealExitTypeEnumType } from '__generated__/graphql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

const ASSET_DATA_FOR_EXIT_QUERY = gql(`
  query fetchAssetDataForExit($companyId: ID!) {
    company(id: $companyId) {
      shareClasses {
        id
        name
        issuePricePerShare
        issueDate
        shareAssets {
          id
          status
          numberOfShares
          deal {
            id
            schemeName
          }
        }
      }
    }
  }
`);

const CREATE_DEAL_EXIT_MUTATION = gql(`
  mutation CreateDealExit(
    $shareAssetIds: [ID!]!
    $exitPricePerShare: Float!
    $exitedAt: String!
    $percentageOfSharesToExit: Float!
    $type: DealExitTypeEnumType!
    $comments: String
  ) {
    createDealExit(
      shareAssetIds: $shareAssetIds
      exitPricePerShare: $exitPricePerShare
      exitedAt: $exitedAt
      percentageOfSharesToExit: $percentageOfSharesToExit
      type: $type
      comments: $comments
    ) 
    {
      id
      type
      createdAt
      updatedAt
    }
  }
`);

const CreateDealExitDialogButton: FC<{ companyId: string }> = ({ companyId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);

  const { loading, error, data, refetch } = useQuery(ASSET_DATA_FOR_EXIT_QUERY, {
    variables: {
      companyId,
    },
  });

  const [createDealExit, { loading: exitLoading, error: exitError }] = useMutation(
    CREATE_DEAL_EXIT_MUTATION,
    {
      refetchQueries: ['fetchDealExitsByCompany'],
    }
  );

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const shareClasses = data.company.shareClasses;
    const shareAssets = shareClasses.flatMap(shareClass =>
      shareClass.shareAssets.map(shareAsset => ({
        ...shareAsset,
        shareClass,
      }))
    );

    return (
      <DialogPanel className="min-w-[60vw] min-h-[60vh]">
        <DialogTitle>Initiate Exit</DialogTitle>
        <DataTable
          data={shareAssets}
          columns={[
            {
              label: 'id',
              fieldName: 'id',
              type: 'SELECT',
            },
            {
              label: 'Share Class',
              fieldName: 'shareClass.name',
            },
            {
              label: 'Number of Shares',
              fieldName: 'numberOfShares',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Deal',
              fieldName: 'deal.schemeName',
            },
            {
              label: 'Issue Date',
              fieldName: 'shareClass.issueDate',
              type: 'DATE',
            },
            {
              label: 'Issue Price Per Share',
              fieldName: 'shareClass.issuePricePerShare',
              type: 'CURRENCY',
            },
          ]}
          onSelect={(assetIds: string[]) => setSelectedAssetIds(assetIds)}
        />
        {selectedAssetIds.length > 0 && (
          <FormPanel
            loading={exitLoading}
            error={exitError}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createDealExit({
                variables: {
                  shareAssetIds: selectedAssetIds,
                  exitPricePerShare: data.exitPricePerShare,
                  exitedAt: data.exitedAt,
                  percentageOfSharesToExit: data.percentageOfSharesToExit,
                  type: data.type,
                  comments: data.comments,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="type"
              label="Type"
              defaultValue={DealExitTypeEnumType.Acquisition}
              options={[
                { label: 'Acquisition', value: DealExitTypeEnumType.Acquisition },
                { label: 'Fundraise', value: DealExitTypeEnumType.Fundraise },
                { label: 'IPO', value: DealExitTypeEnumType.Ipo },
                { label: 'Shares Buyback', value: DealExitTypeEnumType.SharesBuyback },
                { label: 'Shut down', value: DealExitTypeEnumType.ShutDown },
                { label: 'Other', value: DealExitTypeEnumType.Other },
              ]}
              validators={{ required: true }}
            />
            <FormInput
              type="currency"
              fieldName="exitPricePerShare"
              label="Liquidation Price Per Share"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="date"
              fieldName="exitedAt"
              label="Liquidation Date"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="number"
              fieldName="percentageOfSharesToExit"
              label="Percentage of Shares to Liquidate"
              defaultValue={100}
              validators={{ required: true }}
            />
            <FormInput type="textarea" fieldName="comments" label="Comments" defaultValue="" />
          </FormPanel>
        )}
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => {
              // @ts-ignore
              buttonRef.current ? buttonRef.current.cancel() : toggleDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // @ts-ignore
              buttonRef.current?.submit();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </DialogPanel>
    );
  }

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Initiate Exit</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        {renderContent()}
      </Dialog>
    </>
  );
};

export default CreateDealExitDialogButton;
