import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';
import Statistics from 'components/Statistics';

import fundingSourceToReadable from 'utils/enums/funding-source-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import CreateFundClosingDialogButton from './CreateFundClosingDialogButton';
import FundClosingDialog from './FundClosingDialog';

const FUND_CLOSINGS_QUERY = gql(`
  query fetchFundClosingsByFund(
    $fundId: ID!
    $cursor: ID
    $limit: Int
    $filters: FundClosingsFilterType
  ) {
    fund(id: $fundId) {
      id
      fundClosings(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          amount
          status
          investmentEntity {
            id
            name
            user {
              id
              name
            }
          }
          fundingSource
          ownershipPercentage
          createdAt
          updatedAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      statistics {
        totalRaisedAmount
        totalInvestedAmount
        balanceAmount
        numberOfClosings
        fundStatisticsBreakdownByFundClosingStatus {
          status
          numberOfClosings
        }
        fundStatisticsBreakdownByFundClosingFundingSource {
          fundingSource
          numberOfClosings
        }
      }
    }
  }
`);

const FundClosings: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };
  const [closingOnDisplay, setClosingOnDisplay] = useState('');

  const { loading, error, data, refetch, fetchMore, variables } = useQuery(FUND_CLOSINGS_QUERY, {
    variables: {
      limit: 50,
      fundId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fund = data.fund;
  const fundClosings = fund.fundClosings.nodes;
  const pageInfo = fund.fundClosings.pageInfo;
  const statistics = fund.statistics;

  const filters = [
    {
      type: 'status',
      label: 'Status',
      options: [
        {
          label: 'All',
          value: undefined,
        },
        ...statistics.fundStatisticsBreakdownByFundClosingStatus.map(
          ({ status, numberOfClosings }) => ({
            label: `${statusEnumToReadable(status).label} (${numberOfClosings})`,
            value: status,
          })
        ),
      ],
      readable: statusEnumToReadable,
    },
    {
      type: 'fundingSource',
      label: 'Funding Source',
      options: [
        {
          label: 'All',
          value: undefined,
        },
        ...statistics.fundStatisticsBreakdownByFundClosingFundingSource.map(
          ({ fundingSource, numberOfClosings }) => ({
            label: `${fundingSourceToReadable(fundingSource).label} (${numberOfClosings})`,
            value: fundingSource,
          })
        ),
      ],
      readable: fundingSourceToReadable,
    },
  ];

  return (
    <>
      <Statistics
        statistics={[
          {
            label: 'Total Raised',
            value: data.fund.statistics.totalRaisedAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Total Invested',
            value: data.fund.statistics.totalInvestedAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Balance',
            value: data.fund.statistics.balanceAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Number of LPs',
            value: data.fund.statistics.numberOfClosings,
            type: 'NUMBER',
          },
        ]}
      />
      <div>
        <CreateFundClosingDialogButton fundId={fund.id} />
      </div>
      {closingOnDisplay ? (
        <FundClosingDialog
          fundClosingId={closingOnDisplay}
          onClose={() => setClosingOnDisplay('')}
        />
      ) : null}
      <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
      <DataTable
        data={fundClosings}
        searchFields={['investmentEntity.name']}
        onClick={fundClosing => setClosingOnDisplay(fundClosing.id)}
        columns={[
          {
            label: 'Investor Name',
            fieldName: 'investmentEntity.user.name',
          },
          {
            label: 'Entity Name',
            fieldName: 'investmentEntity.name',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Funding Source',
            fieldName: 'fundingSource',
            type: 'FUNDING_SOURCE',
          },
          {
            label: 'Amount',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Ownership Percentage (%)',
            fieldName: 'ownershipPercentage',
            type: 'NUMBER',
          },
          {
            label: 'Closing Date',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default FundClosings;
